import React from "react";

import { navigation } from "../data";

const NavMobile = ({ closeMobileMenu }) => {
  return (
    <nav className="h-full">
      <ul className="flex flex-col justify-center items-center h-full space-y-5">
        {navigation.map((item, index) => {
          return (
            <li
              className="capitalize font-bold text-blue"
              key={index}
              onClick={closeMobileMenu}
            >
              <a href={item.href}>{item.name}</a>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default NavMobile;
