import React from "react";

import DogImg from "../assets/img/dogs/dog-appointment.png";

const Appointment = () => {
  return (
    <section>
      <div>
        <div className="hidden w-full max-w-790px mx-auto lg:flex justify-center">
          <img src={DogImg} alt="zakaži kupanje psa" />
        </div>
        <div className="bg-yellow py-8 px-6 w-full max-w-[790px] h-[250px] mx-auto lg:-mt-5 rounded-[60px] text-center flex flex-col justify-start items-center gap-y-4">
          <h2 className="h2 my-4">Zakaži termin</h2>
          <a
            className="btn w-full max-w-[514px] items-center flex justify-center hover:scale-105 transition-all duration-300"
            href="tel:+381616683997"
          >
            <span> Pozovi nas</span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Appointment;
