import React from "react";

import { navigation } from "../data";

const Nav = () => {
  return (
    <nav className="text-[15px]">
      <ul className="flex gap-x-10">
        {navigation.map((item, index) => {
          return (
            <li className="font-bold" key={index}>
              <a
                className="capitalize hover:text-green transition"
                href={item.href}
              >
                {item.name}
              </a>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Nav;
