import React from "react";
import DogImg from "../assets/img/dogs/dog-contact.png";

const Contact = () => {
  return (
    <section className="bg-yellow-secondary pt-6" id="kontakt">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row justify-around">
          <div className="order-1 lg:-order-1 lg:mt-16">
            <img className="-mb-[12px]" src={DogImg} alt="kontaktiraj nas" />
          </div>
          <div className="flex flex-col items-center justify-center">
            <div className="text-green font-bold mb-3">Kontakt</div>
            <a
              href="tel:+381 61 66 83 997"
              className="text-4xl text-blue font-bold"
            >
              +381 61 66 83 997
            </a>
            <div className="mb-7 text-blue">Svi termini se zakazuju.</div>
            <a
              className="btn hover:scale-105 transition-all duration-300 items-center flex justify-center"
              href="tel:+381 61 66 83 997"
            >
              Zakaži termin
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
