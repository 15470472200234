// import hero slider images
import HeroSlideImage1 from "./assets/img/dogs/dog-slide-1.png";
import HeroSlideImage2 from "./assets/img/dogs/dog-slide-2.png";
import HeroSlideImage3 from "./assets/img/dogs/dog-slide-3.png";
// import dog category images
import DogCateg1 from "./assets/img/dogs/dog-categ-1.png";
import DogCateg2 from "./assets/img/dogs/dog-categ-2.png";
import DogCateg3 from "./assets/img/dogs/dog-categ-3.png";
// import social icons
import { AiFillFacebook, AiFillInstagram } from "react-icons/ai";

export const navigation = [
  {
    name: "početna",
    href: "#",
  },
  {
    name: "cenovnik",
    href: "#cenovnik",
  },
  {
    name: "zakaži termin",
    href: "#kontakt",
  },
];

export const heroSlider = [
  {
    id: 1,
    title: "Vrhunska nega za Vaše šapice",
    image: <HeroSlideImage1 />,
    subtitle:
      "Vaša Zelena šapa - Oaza Groominga u Loznici! Otkrijte našu paletu usluga i osigurajte svom ljubimcu najbolji tretman.",
    buttonText: "Zakaži termin",
  },
  {
    id: 2,
    title: "Osvežite izgled Vašeg ljubimca!",
    image: <HeroSlideImage2 />,
    subtitle:
      "Pružamo vrhunsku uslugu u srcu Loznice, donoseći stil i sjaj vašem ljubimcu.",
    buttonText: "Zakaži termin",
  },
  {
    id: 3,
    title: "Vrhunski grooming u našem malom gradu",
    image: <HeroSlideImage3 />,
    subtitle:
      "Zelena šapa - Više od groominga, to je ljubav prema Vašim ljubimcima.",
    buttonText: "Zakaži termin",
  },
];

export const bundleData = [
  {
    id: 1,
    image: <DogCateg1 />,
    name: "Mali psi",
    dogCategory: "1 - 10 kg",
    services: [
      {
        name: "Sanitarni tretman",
        price: 1500,
        list: [
          "Kupanje",
          "feniranje",
          "brijanje šapa",
          "brijanje sanitarne regije",
          "čišćenje ušiju",
          "sečenje noktiju",
        ],
      },
      {
        name: "Tretman protiv linjanja",
        price: 2000,
        list: [
          "Vađenje mrtve dlake",
          "kupanje",
          "feniranje",
          "brijanje šapa",
          "brijanje sanitarne regije",
          "čišćenje ušiju",
          "sečenje noktiju",
        ],
      },
      {
        name: "Kompletan tretman",
        price: 2500,
        list: [
          "Kupanje",
          "feniranje",
          "brijanje šapa",
          "brijanje sanitarne regije",
          "čišćenje ušiju",
          "sečenje noktiju",
          "šišanje",
        ],
      },
    ],
  },
  {
    id: 2,
    name: "Srednji psi",
    image: <DogCateg2 />,
    dogCategory: "10 - 25 kg",
    services: [
      {
        name: "Sanitarni tretman",
        price: 2000,
        list: [
          "Kupanje",
          "feniranje",
          "brijanje šapa",
          "brijanje sanitarne regije",
          "čišćenje ušiju",
          "sečenje noktiju",
        ],
      },
      {
        name: "Tretman protiv linjanja",
        price: 2500,
        list: [
          "Vađenje mrtve dlake",
          "kupanje",
          "feniranje",
          "brijanje šapa",
          "brijanje sanitarne regije",
          "čišćenje ušiju",
          "sečenje noktiju",
        ],
      },
      {
        name: "Kompletan tretman",
        price: 3000,
        list: [
          "Kupanje",
          "feniranje",
          "brijanje šapa",
          "brijanje sanitarne regije",
          "čišćenje ušiju",
          "sečenje noktiju",
          "šišanje",
        ],
      },
    ],
  },
  {
    id: 3,
    name: "Veliki psi",
    image: <DogCateg3 />,
    dogCategory: "25+ kg",
    services: [
      {
        name: "Sanitarni tretman",
        price: 2700,
        list: [
          "Kupanje",
          "feniranje",
          "brijanje šapa",
          "brijanje sanitarne regije",
          "čišćenje ušiju",
          "sečenje noktiju",
        ],
      },
      {
        name: "Tretman protiv linjanja",
        price: 3300,
        list: [
          "Vađenje mrtve dlake",
          "kupanje",
          "feniranje",
          "brijanje šapa",
          "brijanje sanitarne regije",
          "čišćenje ušiju",
          "sečenje noktiju",
        ],
      },
      {
        name: "Kompletan tretman",
        price: 3700,
        list: [
          "Kupanje",
          "feniranje",
          "brijanje šapa",
          "brijanje sanitarne regije",
          "čišćenje ušiju",
          "sečenje noktiju",
          "šišanje",
        ],
      },
    ],
  },
];

export const social = [
  {
    icon: <AiFillInstagram />,
    href: "https://www.instagram.com/zelenasapa/",
  },

  {
    icon: <AiFillFacebook />,
    href: "https://www.facebook.com/zelenasapa",
  },
];
