import React from "react";

import PhoneIcon from "../assets/img/phone.svg";

const Program = () => {
  return (
    <div>
      <a href="tel:+381616683997">
        <div className="flex lg:gap-x-10 items-baseline justify-end">
          <img
            className="w-[28px] h-[28px] lg:w-[34px] lg:h-[34px]"
            src={PhoneIcon}
            alt="zelena šapa kontakt"
          />
          <div className="lg:text-[23px] text-blue font-bold">
            +381 61 66 83 997
          </div>
        </div>
      </a>

      <div className="text-sm">
        <div className="hidden lg:flex">
          Radno vreme: Termini se zakazuju telefonom
        </div>
      </div>
    </div>
  );
};

export default Program;
